import jQuery from 'jquery'
import TrackableShareAction from './trackable-share-action'

class CopyTextInputElementShare extends TrackableShareAction {
  constructor(fieldSelector) {
    super() // no-op
    this.onShareFocus = this.onShareFocus.bind(this)
    this.fieldSelector = fieldSelector
    jQuery(document).on('focus', this.fieldSelector, this, this.onShareFocus)
  }

  onShareFocus(event) {
    const textarea = jQuery(event.target).closest(this.fieldSelector)
    if (!textarea.data('clicked')) {
      const shareClickToken = textarea.data('share-click-token')
      this.trackEvent('copy_email', textarea, shareClickToken)
      textarea.data('clicked', true)
    }
  }
}

export default CopyTextInputElementShare
