import addComponentLoader, { addSharedStoreComponentLoader, importWithRetry } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

// Components for the petition page. These all share a single vuex store.
const petitionPageComponentsMapping = {
  petitionSign: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-sign" */ './sign/root'))),
  petitionCommentsList: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-comments-list" */ './comments/comments-list'))),
  petitionPromptsBanner: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-prompts-banner" */ '../campaign-prompts/petition-root'))),
  petitionImageUploadAndCrop: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-upload-and-crop" */ './upload-and-crop'))),
  petitionCategoriesSetter: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-categories-setter" */ './categories-setter')))
}

// Component for the embedded signature form
const embeddedComponentsMapping = {
  embeddedPetitionSign: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "embedded-petition-sign" */ './sign/embedded-form')))
}

// Component used on the single-comment page, with no vuex store
const independentCommentsMapping = {
  petitionComment: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-comment" */ './comments/comment')))
}

// The 'petitionSign' for the last argument here means that the storeData of the petitionSign component
// will be used to initialize the store state.
addSharedStoreComponentLoader(petitionPageComponentsMapping, {}, () => importWithRetry(() => import(/* webpackChunkName: "petition-store" */ './store')), 'petitionSign')

addComponentLoader(embeddedComponentsMapping, {}, () => importWithRetry(() => import(/* webpackChunkName: "petition-store" */ './store')))
addComponentLoader(independentCommentsMapping, {})
