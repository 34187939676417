import jQuery from 'jquery'
import FacebookShare from './kinds/facebook-share'
import TwitterShare from './kinds/twitter-share'
import EmailShare from './kinds/email-share'
import WhatsAppShare from './kinds/whats-app-share'
import MessengerShare from './kinds/messenger-share'
import CopyCreatorEmailShare from './kinds/copy-creator-email-share'

jQuery(document).ready(() => {
  // We use the presence of a Facebook Share button to decide to load the Twitter, Messenger, and Facebook share code.
  if (jQuery('.btn.share.facebook').length > 0 || jQuery('.btn.share.twitter').length > 0) {
    new FacebookShare()
    new TwitterShare()
    // We load the code to process Messenger clicks here, even though there is no button on page load, because there will
    // be a button in the daisy chain.
    new MessengerShare()
  }
  if (jQuery('.btn.share.email').length > 0) {
    new EmailShare('.share.email')
  }
  if (jQuery('.btn.share.whatsapp').length > 0) {
    new WhatsAppShare()
  }
  if (jQuery('.share.copy-creator-email').length > 0) {
    new CopyCreatorEmailShare()
  }
})
