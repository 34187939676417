import Cookies from 'js-cookie'
import jQuery from 'jquery'

import { COOKIE_NAME_AGREED_COOKIES } from '../constants'

jQuery(document).ready(() => {
  const cookiesBanner = jQuery('.cookies-consent')
  const sixMonthsInDays = 6 * 30  // Obviously months have different numbers of days, but this should be good enough

  if (cookiesBanner.length > 0) {
    jQuery('.cookies-consent #agree-cookies').on('click', () => {
      Cookies.set(COOKIE_NAME_AGREED_COOKIES, 'all', {expires: sixMonthsInDays})
      document.dispatchEvent(new Event(COOKIE_NAME_AGREED_COOKIES))
      cookiesBanner.slideUp()
    })

    jQuery('.cookies-consent #accept-essential').on('click', () => {
      Cookies.set(COOKIE_NAME_AGREED_COOKIES, 'essential', {expires: sixMonthsInDays})
      cookiesBanner.slideUp()
    })

    if (!Cookies.get(COOKIE_NAME_AGREED_COOKIES)) {
      cookiesBanner.show()
    }
  }
})

