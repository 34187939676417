// This lets v-click-outside work with Vue 3
// See https://github.com/ndelvalle/v-click-outside/issues/601#issuecomment-1383389206
import vClickOutside from 'v-click-outside'
const { bind, unbind } = vClickOutside.directive

const addClickOutsideDirective = function(app) {
  app.directive('click-outside', {
    mounted(el, bindling) {
      bind(el, { value: bindling.value })
    },
    beforeUnmount(el) {
      unbind(el)
    }
  })
}

export { addClickOutsideDirective }
