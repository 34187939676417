import jQuery from 'jquery'

// show/hide are you sure message on radio button change.

jQuery( document ).on('change', '.join-organisation-field-wrapper input[type="radio"]', () => {
  const areYouSureMessage = jQuery('.join-organisation-field-wrapper .radio-are-you-sure-message')

  if (jQuery( '.join-organisation-field-wrapper input[type="radio"]:checked').val() === 'true') {
    areYouSureMessage.hide()
  } else {
    areYouSureMessage.show()
  }
})

