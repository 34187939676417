import jQuery from 'jquery'
import TrackableShareAction from './trackable-share-action'

class MessengerShare extends TrackableShareAction {
  constructor() {
    super() // no-op
    this.onShareClick = this.onShareClick.bind(this)
    jQuery(document).on('click', '.btn.messenger', this, this.onShareClick)
  }

  onShareClick(event) {
    const anchor = jQuery(event.target).closest('.messenger')
    const url = anchor.data('href')
    const shareClickToken = this.trackEvent('Messenger', anchor)
    event.data.openShareBox(this.urlWithShareClickToken(url, shareClickToken))
    return false
  }

  openShareBox(url) {
    window.open(url)
    return false
  }

  urlWithShareClickToken(url, shareClickToken) {
    return this.urlWithShareClickTokenInInnerUrl(url, shareClickToken, 'link')
  }
}
export default MessengerShare
