async function initComponent() {
  const { default: EventAttendees } = await import(/* webpackChunkName: "event-attendees" */ './event-attendees')
  return EventAttendees
}

async function initStore(loaderElement) {
  const { default: buildStore } = await import(/* webpackChunkName: "eventAttendeesStore" */ './store')

  return buildStore({
    ...JSON.parse(loaderElement.dataset.storeData)
  })
}

document.addEventListener('translations:loaded', () => {
  const loaderElement = document.querySelector('.vue-component-loader[data-component-name=eventAttendees]')

  if (loaderElement) {
    initStore(loaderElement).then(store => {
      initComponent().then(attendeesComponent => {
        window.createRootVueComponent(loaderElement, attendeesComponent, {}, store)
      })
    })
  }
})
