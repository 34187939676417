import { register, render } from 'timeago.js'
import { htmlLang } from './i18n/translations'
import { importWithRetry } from '@/utils/component-loader'

const currentLocale = htmlLang()
let timeAgoLocale = ''

importWithRetry(() => import(/* webpackMode: "lazy"*/ `timeago-mappings/${currentLocale}.js`))
  .then(module => {
    const moduleLocale = module.default.locale
    const timeagoModule = module.default.module

    register(moduleLocale, timeagoModule)
    timeAgoLocale = moduleLocale
    loadTimeAgo('abbr.timeago')
  })

function loadTimeAgo(selector) {
  const nodes = document.querySelectorAll(selector)
  if (nodes.length > 0) {
    render(nodes, timeAgoLocale)
  }
}
