import jQuery from 'jquery'
import TrackableShareAction from './trackable-share-action'

class FacebookShare extends TrackableShareAction {
  constructor() {
    super() // no-op
    jQuery(document).on('click', '.btn.facebook', this, event => {
      const anchor = jQuery(event.target).closest('.facebook')
      const shareClickToken = this.trackEvent('Facebook', anchor)
      const url = this.urlWithShareClickToken(anchor.data('href'), shareClickToken)
      event.data.openShareBox(url)
      return false
    })
  }

  openShareBox(url) {
    window.open(url, 'sharer', 'toolbar=0,status=0,width=626,height=436')
    return false
  }
}

export default FacebookShare
