import { EU_COUNTRIES } from './eu-countries'
import { domReady } from '@/utils/dom-ready'

function showOrHideEuContent() {
  const selectedCountry = document.getElementsByClassName('.eu-only-content-switcher')[0].value
  const euDataProcessingConsentWrapperElement = document.getElementsByClassName('.eu-data-processing-consent-wrapper')[0]

  if (EU_COUNTRIES.includes(selectedCountry)) {
    euDataProcessingConsentWrapperElement.style.display = 'block'
  } else {
    euDataProcessingConsentWrapperElement.style.display = 'none'
  }
}

document.addEventListener('change', (event) => {
  if (event.target.matches('.eu-only-content-switcher')) {
    showOrHideEuContent()
  }
})

function initializeEuCountry() {
  const euCountrySelectElement = document.getElementsByClassName('.eu-only-content-switcher')
  if (euCountrySelectElement.length > 0) {
    showOrHideEuContent()
  }
}

domReady(initializeEuCountry)
document.addEventListener('form:loaded', initializeEuCountry)
