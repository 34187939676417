import jQuery from 'jquery'
import TrackableShareAction from './trackable-share-action'

class TwitterShare extends TrackableShareAction {
  constructor() {
    super() // no-op
    this.urlWithShareClickToken = this.urlWithShareClickToken.bind(this)
    this.onShareClick = this.onShareClick.bind(this)
    jQuery(document).on('click', '.btn.twitter', this, this.onShareClick)
  }

  onShareClick(event) {
    const anchor = jQuery(event.target).closest('.twitter')
    let url = anchor.data('href')
    url = url || anchor.attr('href')
    const shareClickToken = this.trackEvent('Twitter', anchor)
    url = this.urlWithShareClickToken(url, shareClickToken)
    const popup = window.open('about:blank', 'sharer', 'toolbar=0,status=0,width=626,height=436')
    popup.location.href = url
    return false
  }

  urlWithShareClickToken(url, shareClickToken) {
    return this.urlWithShareClickTokenInInnerUrl(url, shareClickToken, 'url')
  }
}

export default TwitterShare
