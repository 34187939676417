import { addClickOutsideDirective } from '@/utils/clicked-outside'
import { applyCommonPluginsToVueApp } from '@/vue-init'
import { createApp } from 'vue'
import i18n from './translations'
import I18nPlugin from '@/i18n/i18n-plugin'
import TimeAgoFormat from '@/helpers/time-ago-format'

window.createRootVueComponent = function(el, component, props, store=null) {
  const app = createApp(component, props)
  applyCommonPluginsToVueApp(app)
  app.use(I18nPlugin, {i18n})
  app.use(TimeAgoFormat)
  addClickOutsideDirective(app)
  if (store) {
    app.use(store)
  }
  app.mount(el)
}
