import jQuery from 'jquery'
import ajax from '../../helpers/ajax'
import TrackableShareAction from './trackable-share-action'

class EmailShare extends TrackableShareAction {
  constructor(shareTriggerElementsSelectors) {
    super() // no-op
    this.onShareClick = this.onShareClick.bind(this)
    this.shareTriggerElementsSelectors = shareTriggerElementsSelectors

    jQuery(document).on('click', this.shareTriggerElementsSelectors, this.onShareClick)
  }

  onShareClick(event) {
    const anchor = jQuery(event.target).closest('.email')
    const shareClickToken = this.trackEvent('Email', anchor)
    const emailShareDataHref = anchor[0].dataset['emailShareDataHref']

    ajax.get({
      url: emailShareDataHref,
      params: {
        share_click_token: shareClickToken
      },
      successHandler: ({ subject, body }) => {
        window.location.assign(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)
      }
    })
  }
}

export default EmailShare
