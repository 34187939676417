import addComponentLoader, { importWithRetry } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

const componentMapping = {
  calendarEventsListPage: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "calendar-events-list-page" */ './calendar-events-list-page'))),
  embeddableList: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "embeddable-list" */ './embeddable-list'))),
  embeddableMapWithoutSidebar: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "embeddable-map-without-sidebar" */ './embeddable-map-without-sidebar'))),
  embeddableMapWithSidebar: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "embeddable-map-with-sidebar" */ './embeddable-map-with-sidebar'))),
  eventsListPage: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "events-list-page" */ './events-list-page'))),
  localMapWithListPage: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "local-map-with-list" */ './local-map-with-list-page'))),
  partnershipEventsList: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "partnership-events-list" */ './partnership-events-list')))
}

addComponentLoader(componentMapping, {}, () => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "local-map-store-getter" */ './store')))
