import addComponentLoader, { importWithRetry } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

const discussionForumComponentMapping = {
  discussionForumContainer: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "discussion-forum-container" */ '../discussion-forum/discussion-forum-container'))),
  eventDiscussionForumLoader: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-discussion-forum-loader" */ '../events/discussion-forum-loader')))
}

const discussionForumThreadComponentMapping = {
  discussionForumThreadContainer: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "discussion-forum-thread-container" */ '../discussion-forum/discussion-forum-thread-container')))
}

addComponentLoader(discussionForumComponentMapping, {}, () => importWithRetry(() => import(/* webpackChunkName: "discussion-forum-store-getter" */ './store/forum')))
addComponentLoader(discussionForumThreadComponentMapping, {}, () => importWithRetry(() => import(/* webpackChunkName: "discussion-forum-thread-store-getter" */ './store/thread')))
