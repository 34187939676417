import jQuery from 'jquery'
import TrackableShareAction from './trackable-share-action'

class WhatsAppShare extends TrackableShareAction {
  constructor() {
    super() // no-op
    this.onShareClick = this.onShareClick.bind(this)
    jQuery(document).on('click', '.btn.whatsapp', this, this.onShareClick)
  }

  onShareClick(event) {
    const anchor = jQuery(event.target).closest('.whatsapp')
    const shareClickToken = this.trackEvent('WhatsApp', anchor)
    let url = anchor.data('href')
    url = url || anchor.attr('href')
    url = this.urlWithShareClickToken(url, shareClickToken)
    window.open(url)
    return false
  }
}
export default WhatsAppShare
