import ajax from '../../helpers/ajax'
import {v4 as uuid} from 'uuid'
import { trackShareOpen } from '../../analytics/track'

class TrackableShareAction {
  trackEvent(shareMedium, element, shareClickToken) {
    const entityType = element.data('entity-type')
    const entitySlug = element.data('entity-slug')

    const shareClicksPath = element.data('share-clicks-path')

    // Tell other Javascript on the page
    document.dispatchEvent(new Event('agra:share-button-clicked'))

    // Track analytics
    trackShareOpen(shareMedium, entityType, entitySlug)

    // If applicable, return shareClickToken, and create ShareClick asynchronously
    if (shareClicksPath) {
      if (!shareClickToken) {
        shareClickToken = uuid()
      }
      ajax.post({
        url: shareClicksPath,
        data: {
          token: shareClickToken
        }
      })
      return shareClickToken
    }
    return null
  }

  urlWithShareClickToken(url, shareClickToken, encodeURIParam = true) {
    if (shareClickToken !== null) {
      if (encodeURIParam) {
        return url + encodeURIComponent(`&share=${shareClickToken}`)
      } else {
        const urlObj = new URL(url)
        urlObj.searchParams.append('share', shareClickToken)
        return urlObj.toString()
      }
    }
    return url
  }

  urlWithShareClickTokenInInnerUrl(url, shareClickToken, innerUrlParamName) {
    if (shareClickToken === null) {
      return url
    }

    const urlObj = new URL(url)

    // add the share_click_id param to inner url param
    const searchParams = new URLSearchParams(urlObj.search.slice(1))
    let innerUrl = searchParams.get(innerUrlParamName)

    innerUrl += `&share=${shareClickToken}`

    urlObj.searchParams.set(innerUrlParamName, innerUrl)
    return urlObj.href
  }
}

export default TrackableShareAction
