// Replace styles fetched in the background once they are loaded.
// Works in all browsers with JavaScript enabled.
document.querySelectorAll('link[data-swap-on-load="true"]').forEach((link) => {
  // If the stylesheet was already loaded, do the swap immediately
  if (link.sheet) {
    link.setAttribute('media', 'all')
  } else {
    link.addEventListener('load', () => {
      link.setAttribute('media', 'all')
    })
  }
})
