import moment from 'moment'

const TimeAgoFormat = {
  install(app) {
    app.config.globalProperties.timeAgo = function(datetime, format = 'date.formats.default') {
      let twoDaysAgo = moment().subtract(2, 'day')

      if (twoDaysAgo.isBefore(datetime)) {
        const momentDateTime = moment(datetime)
        momentDateTime.locale(this.i18n.locale())
        return momentDateTime.fromNow()
      } else {
        return this.i18n.l(format, datetime)
      }
    }
  }
}

export default TimeAgoFormat
