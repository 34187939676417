import { Popover } from 'bootstrap'

const popoverTemplate = '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-inner"><div class="popover-body"><p></p></div></div></div>'
const inputQuery = 'input[rel=popover], textarea[rel=popover], input[data-bs-toggle=popover], textarea[data-bs-toggle=popover]'
const linkButtonQuery = 'a[rel=popover], a[data-bs-toggle=popover], button[data-bs-toggle=popover]'

export function setUpPopovers() {
  document.querySelectorAll(inputQuery).forEach((el) => {
    new Popover(el, {
      trigger: 'focus',
      html: true,
      template: popoverTemplate,
      placement: el.dataset.bsPlacement || 'right'
    })
  })

  document.querySelectorAll(linkButtonQuery).forEach((el) => {
    new Popover(el, {
      trigger: el.dataset.bsTrigger || 'click',
      html: true,
      template: popoverTemplate,
      placement: el.dataset.bsPlacement || 'right'
    })
  })
}
