import ajax from '@/helpers/ajax'
import zip from 'lodash/zip'
import chunk from 'lodash/chunk'
import { importWithRetry } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

const Embedly = function (element, url) {
  this.init(element, url)
}

Embedly.prototype = {
  init: function(elem, url) {
    this.elem = elem
    this.url = url
  }
}

let embedlyComponent = null // Lazy-loaded

const showEmbedRegex = new RegExp('showEmbed=true$')
const skipEmbedRegex = new RegExp('showEmbed=false$')
const bypassPlaceholderRegex = new RegExp('bypassPlaceholder=true$')

function isAllowedProvider(providerName) {
  // If embedly.allowedProviders is not set, then all providers are allowed
  if (!window.Agra.Configuration.embedly.allowedProviders) {
    return true
  }

  // Accept providers on the list, case-insensitively
  return window.Agra.Configuration.embedly.allowedProviders.some(
    (allowedProvider) => allowedProvider.toLowerCase() === providerName.toLowerCase()
  )
}

export function shouldEmbedContent({ url, embedlyResponse }) {
  if (skipEmbedRegex.test(url)) {
    // The URL ends with showEmbed=false, explicitly asking us NOT to embed it.
    // We always respect this setting.
    return false
  } else if (['photo', 'video'].includes(embedlyResponse.type)) {
    // Photos and videos are embedded by default, assuming the provider is allowed
    return isAllowedProvider(embedlyResponse.provider_name)
  } else if (showEmbedRegex.test(url)) {
    // The URL ends with showEmbed=true, explicitly asking us to embed it.

    // This feature is suppressed in privacy mode.
    if (window.Agra.Configuration.embedly.privacyMode) {
      return false
    }

    // Go ahead and embed this content as requested, assuming the provider is allowed
    return isAllowedProvider(embedlyResponse.provider_name)
  } else {
    // This isn't a photo, a video, or content we've been explicitly asked to embed, so don't.
    return false
  }
}

function mountElement(linkElement, href, resp) {
  if (shouldEmbedContent({ url: href.url, embedlyResponse: resp })) {
    // Vue 3 puts the component within the element it's mounted to, rather than replacing the element.
    // Since we do not want to keep the original <a href="something"> element, we need to replace it with
    // something innocuous. So we replace the entire <a...></a> element with an empty span, and then
    // insert the Embedly component within the span.
    const el = document.createElement('span')
    linkElement.replaceWith(el)

    // We use embedly and embed the media
    embedlyComponent = embedlyComponent || defineAsyncComponent(() => importWithRetry(() => import( /* webpackMode: "lazy" */ './embedly/embedly-element')))
    window.createRootVueComponent(el, embedlyComponent, {
      embedlyPrivacyMode: window.Agra.Configuration.embedly.privacyMode,
      embedlyResponse: resp,
      bypassPlaceholder: bypassPlaceholderRegex.test(href.url)
    })
  } else {
    // We leave the href element as is, skipping embedly.
    return
  }
}

function replaceElements(pairs) {
  pairs.forEach((pair) => {
    const embedlyHref = pair[0]
    const embedlyApiResponse = pair[1]
    mountElement(embedlyHref.elem, embedlyHref, embedlyApiResponse)
  })
}

function processElements(embedlyElements) {
  const tokyoHost = window.Agra.Configuration.tokyoUrlBase
  const encodedUrls = embedlyElements.map(element => encodeURIComponent(element.url)).join(',')
  const url = `${tokyoHost}/cached_url/embedly?maxwidth=460&secure=true&wmode=opaque&urls=${encodedUrls}`

  ajax.get({
    url: url,
    camelCaseResponse: false,
    throwOnFailure: true,
    successHandler: (data) => {
      // We zip together the urls and the data so we have the element to be replaced alongside the embed information.
      const zippedElems = zip(embedlyElements, data)
      replaceElements(zippedElems)
    }
  }).catch(() => {
    // If there is a network failure contacting embedly, we do not need to notify the user about it,
    // because there's nothing they can do. Just silently move on.
  })
}

export function convertAnchorsToEmbedsForSelector(selector) {
  const urlRe = /(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/

  // get the list of possible URLs
  const potentialElements = selector.map(element => new Embedly(element, element.href))

  // filter out non-URL hrefs
  const embedlyElements = potentialElements.filter(potentialEmbed => urlRe.test(potentialEmbed.url))

  // split URLs into chunks of 10 to be processed, otherwise the GET URL can be too long.
  const embedlyElementsChunks = chunk(embedlyElements, 10)

  embedlyElementsChunks.forEach((elem) => processElements(elem))
}
